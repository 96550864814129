import Footer from "../../components/footer/Footer";
import MeetingsHeader from "../../components/header/MeetingsHeader";
import UpcomingMeetings from "./UpcomingMeetings";
import "./styles.css";
import CompletedMeetings from "./CompletedMeetings";
import { useEffect, useState } from "react";
import NoMeeting from "./NoMeeting";
import { GET_ALL_MEETINGS } from "../../api/endpoints";
import { call } from "../../api/axios";
import { IonHeader, IonImg } from "@ionic/react";
import { FaChevronLeft } from "react-icons/fa6";
import { MdOutlineLiveTv, MdOutlineUpcoming } from "react-icons/md";
import { useHistory, useParams } from "react-router";

function Meetings() {
  const id = localStorage.getItem("register_id");
  const params = useParams();
  const history = useHistory();
  const [allUpcomingMeetings, setAllUpcomingMeetings] = useState([]);
  const [allCompletedMeetings, setAllCompletedMeetings] = useState([]);
  const [activeIndex, setActiveIndex] = useState(params.id === "complted" ? 1 : 0);
  const handleActiveIndex = (item) => {
    setActiveIndex(item);
    item === 0 ? history.push("/meetings/upcoming") : history.push("/meetings/complted");
  };

  const getAllMettings = async () => {
    await call(GET_ALL_MEETINGS, {
      register_id: id,
    }).then((res) => {
      if (res.data.statusCode === 200) {
        const currentTimeStamp = Date.now();
        const upcomingMeetings = [];
        const completedMeetings = [];

        res?.data?.data?.forEach((item) => {
          const createdTimeStamp = item.created_time_stamp;
          const timeDifference = currentTimeStamp - createdTimeStamp;
          const isWithin24Hours = timeDifference <= 24 * 60 * 60 * 1000;

          if (!item.completed_status && isWithin24Hours) {
            upcomingMeetings.push(item);
          } else {
            completedMeetings.push(item);
          }
        });

        setAllUpcomingMeetings(upcomingMeetings);
        setAllCompletedMeetings(completedMeetings);
      }
    });
  };

  useEffect(() => {
    getAllMettings();
  }, []);

  return (
    <div className="main-container">
      <IonHeader className="user-outer-div">
        <div className="flex-jc-c">
          <div className="flex-center custom-ionicon-container custom-back-icon" onClick={() => history.push("/")}>
            <FaChevronLeft className="font-25" />
          </div>
          <img className="custom-ionicon-container custom-meeting-image ml-5" src={localStorage?.getItem("profileImage")} />
        </div>
        <div className="header-right">
          <div>
            <div className={`mar-5 custom-ionicon-container flex-center ${activeIndex === 0 ? "green-color" : ""}`} onClick={() => handleActiveIndex(0)}>
              <MdOutlineLiveTv />
            </div>
          </div>
          <div>
            <div className={`mar-5 custom-ionicon-container flex-center ${activeIndex === 1 ? "green-color" : ""}`} onClick={() => handleActiveIndex(1)}>
              <MdOutlineUpcoming />
            </div>
          </div>
        </div>
      </IonHeader>
      {false ? (
        <NoMeeting />
      ) : (
        <>
          {activeIndex === 1 && <CompletedMeetings allCompletedMeetings={allCompletedMeetings} />}
          {activeIndex === 0 && <UpcomingMeetings allUpcomingMeetings={allUpcomingMeetings} />}
        </>
      )}
      <Footer />
    </div>
  );
}

export default Meetings;
