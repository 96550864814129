import { useState,useEffect } from "react";
import { call } from "../config/axios";
import {
  GET_LIVE_MEETING
} from "../config/endpoints";
import ScoreBoard from "./ScoreBoard";
import LiveStreaming from "./LiveStreaming";
import Line from "./Line";
import "./styles.css";
import moment from "moment";
import coins from "../assets/images/coin.png";
import moneyImage from "../assets/images/money.png";
import { BiSolidChevronLeft } from "react-icons/bi";
import MatchPositionTables from "./MatchPositionTables"
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { IoSettingsOutline } from "react-icons/io5";
import Select from "react-select";

const professionalUser = [
    { value: 4, label: "Match Position" },  
    { value: 1, label: "Score Board" },  
    { value: 2, label: "Live Streaming" },  
    { value: 3, label: "Line" },  
] 

function Calling(props) {
  const { meetingId } = props;
  const [showDownIcon, setShowDownIcon] = useState(true);
    const [liveMeeting, setLiveMeeting] = useState({});
    const [showUpIcon, setShowUpIcon] = useState(false);

  const index = liveMeeting.meeting_type === "Personal" ? 0 : 4;
  const [activeIndex, setActiveIndex] = useState(index);
  const values =  liveMeeting.meeting_type != "Personal" ? professionalUser : null;
    const buttons = values;
  const getLiveMeetingData = async () => {
        const creator_id =  localStorage.getItem("creator_id");
        return await call(GET_LIVE_MEETING, {
            creator_id: creator_id,
            meeting_id: meetingId,
        })
            .then((res) => {
                console.log("liveMeeting", res)
                setLiveMeeting(res.data?.data?.Items?.[0]);
            })
            .catch((err) => {
                throw err;
            });
    };

    useEffect(() => {

        getLiveMeetingData();
    }, [props.meetingId]);
 
  const [minSidebar, setMinSidebar] = useState(true);
  const [maxSidebar, setMaxSidebar] = useState(false);
  const handleOpenMaxSidebar = () => {
    setMaxSidebar(true);
    setMinSidebar(false);
  };
  const handleOpenMinSidebar = () => {
    setMinSidebar(true);
    setMaxSidebar(false);
  };

  const [admitPopupOpen, setAdmitPopupOpen] = useState(false);
  const handleAdmitPopupOpen = () => {
    setAdmitPopupOpen(true);
  };
  const handleClickDown = () => {
        setShowDownIcon(false);
        setShowUpIcon(true);
    };
    const handleClickUP = () => {
        setShowDownIcon(true);
        setShowUpIcon(false);
    };

  return (
    <div className="d-flex">

        <div className="match-position-header-container">
            <div className="flex-center" 
            // onClick={() => onBackClick()}
            >
                <BiSolidChevronLeft
                    className="header-icons custom-back-icon"
                />
            </div>
            <img className="header-images" src={coins} />
            {liveMeeting.meeting_type === "Personal" ? (
                <></>
              ):(
                  <Select
                    className="w-100 react-select-container cursor-pointer border-yelo rounded"
                    classNamePrefix="react-select"
                    placeholder="Select"
                    options={buttons}
                    value={buttons[activeIndex]}
                    onChange={(e) => setActiveIndex(e.value)}
                    isSearchable={true}
                  />
              )}
            {/* <p>
                Match Position
                <br />
                <span>Check your position</span>
            </p> */}
            &nbsp;&nbsp;
            {showDownIcon && (
                <FaChevronDown className="header-icons" 
                onClick={handleClickDown} 
                />
            )}
            {showUpIcon && (
                <FaChevronUp 
                onClick={handleClickUP} 
                className="header-icons" />
            )}
            <img className="header-images" src={moneyImage} />
        </div>
         {showUpIcon && activeIndex === 4 ? (
                    <MatchPositionTables
                        matchId={liveMeeting?.matchId}
                        liveMeeting={liveMeeting}
                    />
                ):showUpIcon && activeIndex === 1  ?(
                       <ScoreBoard /> 
                ):showUpIcon && activeIndex === 2 ?(
                    <>
                    <LiveStreaming />
                    </>
                ):showUpIcon && activeIndex === 3 ?(
                    <>
                    <Line />
                    </>
                ):(
                    <></>
                )}
        {/* <div className="w-100 num-btn-bg rounded p-2  bgDarkside">
            
            <div className="d-flex flex-wrap font-14">
              <div className="detailMeeting py-1 px-3 bg-blue mt-1">
                
                {liveMeeting?.match_name && (
                    <span>{liveMeeting?.match_name}</span> 
                )}
                <span>{liveMeeting?.event_name}</span>
                <span>{moment(liveMeeting?.given_time_stamp).format("DD-MM-YYYY")} &nbsp; {moment(liveMeeting?.given_time_stamp).format("hh:mm A")}
                </span> 
              </div>
            </div>
            <div className="d-flex flex-wrap font-14">
              <div className="detailMeeting py-1 bg-blue mt-1">
                
                
                   {activeIndex === 1 && <ScoreBoard />}
                  {activeIndex === 2 && <LiveStreaming />}
                  {activeIndex === 3 && <Line />} 
                  {activeIndex === 4 && <MatchPosition />}
              </div>
            </div>
        </div> */}
          
    </div>
  );
}

export default Calling;
