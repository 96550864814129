import { Images } from "../Images/index";
import miIcon from "./Assets/miIcon.png";
import cskIcon from "./Assets/cskIcon.png";
import copyIcon from "./Assets/copyIcon.png";

function ScoreBoard() {
    const latestBalls = [
    { run: "4" },
    { run: "6" },
    { run: "1" },
    { run: "0" },
    { run: "2" },
    { run: "3" },
  ];
  return (
    <div className="custom-box-shadow table-page-conatainer">
      <div className="vc-score-board-container">
      <div className="score-live-bar">
        <div className="vc-live-button">
          <span>Live</span>
        </div>
        <div className="vc-live-button">
          Info
        </div>
        <div className="Over-line">
          over4
          {latestBalls.map((value, index) => (
            <div key={index}>{value.run === "4" || value.run === "6" ? <span>{value.run}</span>:value.run}</div>
          ))}
        </div>
      </div>
      <div className="score-card-container">
        <div className="live-match-card">Live Match</div>
        <div className="teans-card">
          <div className="team-score display-flex-property">
            <div>
              <img src={miIcon}></img>
            </div>
            MI
          </div>
          <div className="team-score ">
            <span style={{color:"#ffa800",fontWeight:"700",marginTop:"1rem"}}>0/0</span>
            overs 0.0
          </div>
          <h2>vs</h2>
          <div className="team-score ">
            <span style={{color:"#ffa800",fontWeight:"700",marginTop:"1rem"}}>185/6</span>
            overs 20
          </div>
          <div className="team-score display-flex-property">
            <div>
              <img src={cskIcon}></img>
            </div>
            CSK
          </div>
        </div>
        <div className="run-rate-container">
          <div className="run-rate-innner-div">
            <span>CRR:0.0</span>
            <span>Csk need 67 runs to win</span>
          </div>
          <div className="run-rate-innner-div">
            <span>RRR:9.3</span>
            <span>120 Balls</span>
          </div>
        </div>
        </div>
        <span style={{textAlign:"left"}}>Most realiable Online ID</span>
        <div className="texch-link-container">
          <span>https://texch.app/#/</span>
          <img
            onClick={() => {
              navigator.clipboard.writeText("https://texch.app/#/");
            }}
            src={copyIcon}
          ></img>
        </div>
        <div className="live-adds-conainer">
          <div className="left-adds-div">
            <div className="d-flex">
              <span className="live-adds-display">97</span>
              <p className="live-adds-display">97</p>
            </div>
            <div>Dono(Both)</div>
          </div>
          <div className="latest-ball-score">
            
            <span className="six-number">six</span>
            <span className="six">6</span>
          </div>
        </div>
        <div className="fancy-declaration-container">
          <div className="fancy-declaration-no display-flex-property display-space-between-property">
            <div>
              52 <span className="font-size-10">No</span>
            </div>
            <div className="fancy-declaration-runs">
              <span className="font-size-10">Balls</span> 36
            </div>
          </div>
          <div className="fancy-declaration-yes display-flex-property display-space-between-property">
            <div>
              52 <span className="font-size-10">Yes</span>
            </div>
            <div className="fancy-declaration-runs">
              <span className="font-size-10">Runs</span> 54
            </div>
          </div>
        </div>
        <center>
          <div className="over-highlight-container">
            <div>
              <span className="font-size-20">06</span>
              <br />
              over
            </div>
          </div>
        </center>
      
    </div>
    </div>
  );
}

export default ScoreBoard;