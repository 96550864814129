import { useEffect, useState } from "react";
import "./common.css";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import SignIn from "../../pages/signup/SignIn";
import ReferID from "../referrals/ReferID";
import ResetPassword from "../resetpassword/ResetPassword";
import Homepage from "../../components/homepage/Homepage";
import { openLoginModal, setSignUpData } from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import LoginPopup from "../../pages/signup/LoginPopup";
import { GET_ALL_MEETINGS } from "../../api/endpoints";
import { call } from "../../api/axios";

function Home(props) {
  const [openModal, setOpenModel] = useState(false);
  const [referModal, setReferModal] = useState(false);
  const [resetpassword, setResetPassword] = useState(false);
  const register_id = localStorage.getItem("register_id");
  const loginModalStatus = useSelector(
    (State) => State?.userReducer?.loginModalStatus
  );
  const [allUpcomingMeetings, setAllUpcomingMeetings] = useState();
  const [allCompletedMeetings, setAllCompletedMeetings] = useState([]);
  const [activeUserDropdown, setActiveUserDropdown] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const id = localStorage.getItem("register_id");

  const handleActiveUserDropdown = () => {
    setActiveUserDropdown((prev) => !prev);
    setReferModal(false);
  };
  const dispatch = useDispatch();

  const handleOpenModal = () => {
    setOpenModel((prev) => !prev);
    dispatch(setSignUpData({}));
  };
  const handleReferModal = () => {
    setReferModal((prev) => !prev);
    setActiveUserDropdown(false);
    setLoginModalOpen(false);
  };
  const handleResetPassword = () => {
    setResetPassword((prev) => !prev);
  };

  const onOpen = () => {
    if (!loginModalStatus) {
      dispatch(openLoginModal(true));
    }
  };

  const getAllMettings = async () => {
    await call(GET_ALL_MEETINGS, {
      register_id: id,
    }).then((res) => {
      if (res.data.statusCode === 200) {
        const currentTimeStamp = Date.now();
        const upcomingMeetings = [];
        const completedMeetings = [];

        res?.data?.data?.forEach((item) => {
          const createdTimeStamp = item.created_time_stamp;
          const timeDifference = currentTimeStamp - createdTimeStamp;
          const isWithin24Hours = timeDifference <= 24 * 60 * 60 * 1000;

          if (!item.completed_status && isWithin24Hours) {
            upcomingMeetings.push(item);
          } else {
            completedMeetings.push(item);
          }
        });

        setAllUpcomingMeetings(upcomingMeetings);
        setAllCompletedMeetings(completedMeetings);
      }
    });
  };

  useEffect(() => {
    if (register_id) {
      getAllMettings();
    }
    dispatch(openLoginModal(false));
  }, []);

  return (
    <div
      className="main-container"
      onClick={register_id ? null : () => onOpen()}
    >
      <Header
        loginModalOpen={loginModalOpen}
        openModal={openModal}
        handleResetPassword={handleResetPassword}
        handleReferModal={handleReferModal}
        activeUserDropdown={activeUserDropdown}
        setActiveUserDropdown={setActiveUserDropdown}
        handleActiveUserDropdown={handleActiveUserDropdown}
      />
      <Homepage
        handleOpenModal={handleOpenModal}
        handleReferModal={handleReferModal}
        allUpcomingMeetings={allUpcomingMeetings || []}
        allCompletedMeetings={allCompletedMeetings}
      />
      {!register_id && (
        <SignIn handleOpenModal={handleOpenModal} openModal={openModal} />
      )}
      {!register_id && <LoginPopup setOpenModel={setOpenModel} />}
      <ReferID handleReferModal={handleReferModal} referModal={referModal} />
      <ResetPassword
        handleResetPassword={handleResetPassword}
        resetpassword={resetpassword}
      />
      <Footer />
    </div>
  );
}

export default Home;
