import { FaPhotoFilm } from "react-icons/fa6";
import { VscSend } from "react-icons/vsc";
import { BsFullscreen,BsFullscreenExit } from "react-icons/bs";
import { RiVolumeUpFill } from "react-icons/ri";
import { HiOutlinePlayCircle,HiOutlinePauseCircle } from "react-icons/hi2";

import { useState,useRef } from "react";
import moment from "moment";
import Select from "react-select";

function Line() {

     const audioContainerRef = useRef(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [selectedMatch, setSelectedMatch] = useState("");
  const [volume,setVolume] = useState(false);
  const [volumeLevel, setVolumeLevel] = useState(1); 
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);
  const playerRef = useRef(null); 


  const handlePlayPause = () => {
    if (!isPlaying) {
      audioRef.current.play();
      setIsPlaying(true);
    } else {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

   const enterFullscreen = () => {
    if (audioContainerRef.current) {
      if (audioContainerRef.current.requestFullscreen) {
        audioContainerRef.current.requestFullscreen();
      } else if (audioContainerRef.current.webkitRequestFullscreen) {
        audioContainerRef.current.webkitRequestFullscreen(); // Safari
      } else if (audioContainerRef.current.mozRequestFullScreen) {
        audioContainerRef.current.mozRequestFullScreen(); // Firefox
      } else if (audioContainerRef.current.msRequestFullscreen) {
        audioContainerRef.current.msRequestFullscreen(); // IE/Edge
      }
      setIsFullscreen(true);
    }
  };

  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
    setIsFullscreen(false);
  };


   const matches = [
    { id: 1, value: "Join Users", label: "Match 1", videoUrl: "https://www.example.com/match1" },
    { id: 2, value: "Join Users", label: "Match 2", videoUrl: "https://www.example.com/match2" },
    { id: 3, value: "Join Users", label: "Match 3", videoUrl: "https://www.example.com/match3" },
  ];

  const languages = [
    {value:"Hindi",label:"Hindi"},
    {value:"Telugu",label:"Telugu"},
    {value:"Malayalam",label:"Malayalam"},
  ]

  const handleMatchSelect = (e) => {
    
  };
  const handleLanguageSelect = (e) =>{

  }
  const showVolume = () =>{
        setVolume(!volume)

  }
   const handleVolumeChange = (e) => {
    const newVolume = e.target.value;
    audioRef.current.volume = newVolume; 
    setVolume(newVolume);
  };
  return (
    <div className="custom-box-shadow table-page-conatainer">
       <div className="dropdown-container">
             <Select
              className="w-100 react-select-container cursor-pointer border-yelo rounded"
              classNamePrefix="react-select"
              placeholder="Select Match"
              options={matches}
              value={selectedMatch}
              onChange={(e) => handleMatchSelect(e)}
              isSearchable={true}
            />
                <br />
             <Select
              className="w-100 react-select-container cursor-pointer border-yelo rounded"
              classNamePrefix="react-select"
              placeholder="Select Language"
              options={languages}
              onChange={(e) => handleLanguageSelect(e)}
              isSearchable={true}
            />
      </div>

        <div class="audio-container">
          <div className="audio-live">
            <audio ref={audioRef} src="https://www.learningcontainer.com/wp-content/uploads/2020/02/Kalimba.mp3" type="audio/mp3" hidden />
                    <div className="play-stop" onClick={handlePlayPause}>
                      {isPlaying ? (
                        <HiOutlinePauseCircle className="icon-play" />
                      ) : (
                        <HiOutlinePlayCircle className="icon-play" />
                      )}
                    </div>
            <div class="audio-controls">
                 <input
                   type="range"
                   id="volume-control"
                   min="0"
                   max="1"
                   step="0.1"
                   value={volume}
                   style={{display: volume ? "block" : "none"}}
                   className="volumeLine"
                   onChange={handleVolumeChange}
                 />
                <div className="audio-control" onClick={isFullscreen ? exitFullscreen : enterFullscreen}>
                {isFullscreen ? <BsFullscreenExit className="icon-fullscreen" /> : <BsFullscreen className="icon-fullscreen" />}
              </div>
              <div className="audio-control" >
                <RiVolumeUpFill  onClick={() => showVolume()}/>
              </div>
              
            </div>
          </div>
        </div>

      
    </div>
  );
}

export default Line;
